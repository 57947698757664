import React from 'react';
import { getContactHref } from '../../../utils';
import * as styles from './Author.module.scss';
import { useSiteMetadata } from '../../../hooks';

const Author = () => {
   const site = useSiteMetadata();
   const author = site?.author;

   return (
      <div className={styles['author']}>
         <p className={styles['author__bio']}>
            {author.bio}
            <a
               className={styles['author__bio_twitter']}
               href={getContactHref('twitter', author!.contacts.twitter)}
               rel="noopener noreferrer"
               target="_blank"
            >
               <strong>{author.name}</strong> on Twitter
            </a>
         </p>
      </div>
   );
};

export default Author;
