import React from 'react';
import { graphql } from 'gatsby';
import Post from '../components/Post';
import Layout from '../components/Layout';
import { useSiteMetadata } from '../hooks';
import type { MarkdownRemark } from '../types';

type Props = {
   data: {
      markdownRemark: MarkdownRemark;
   };
};

const PostTemplate = ({ data }: Props) => {
   const { title: siteTitle, subtitle: siteSubtitle } = useSiteMetadata();
   const { frontmatter } = data.markdownRemark;
   const metaDescription = frontmatter.description || siteSubtitle;

   return (
      <Layout
         title={`${frontmatter.title} - ${siteTitle}`}
         description={metaDescription}
         socialImage={frontmatter.socialImage?.publicURL}
      >
         <Post post={data.markdownRemark} />
      </Layout>
   );
};

export const query = graphql`
   query PostBySlug($slug: String!) {
      markdownRemark(fields: { slug: { eq: $slug } }) {
         id
         html
         fields {
            slug
            tagSlugs
         }
         frontmatter {
            date
            description
            tags
            title
            socialImage {
               publicURL
            }
         }
      }
   }
`;

export default PostTemplate;
