import React from 'react';
import * as styles from './Meta.module.scss';

type Props = {
   date: string;
};

const Meta = ({ date }: Props) => (
   <div className={styles['meta']}>
      <p className={styles['meta__date']}>
         Written{' '}
         {new Date(date).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric'
         })}
      </p>
   </div>
);

export default Meta;
