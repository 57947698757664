import React from 'react';
import * as styles from './Content.module.scss';

type Props = {
   body: string;
   title: string;
};

const Content = ({ body, title }: Props) => (
   <div className={styles['content']}>
      <h2 className={styles['content__title']}>{title}</h2>
      <div className={styles['content__body']} dangerouslySetInnerHTML={{ __html: body }} />
   </div>
);

export default Content;
